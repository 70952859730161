<template>
  <div class="card card-user">
    <div class="image">
      <!--<img src="static/img/background/damir-bosnjak.jpg" alt="...">-->
    </div>
    <div class="card-body">
      <div class="author">
        <a href="#">
          <img class="avatar border-gray" :src= userDetails.user_profile_image  alt="prfile photo">
          <h5 class="title">{{ userDetails.user_name }}</h5>
        </a>
        <p class="description">
          {{ userDetails.user_email }}
        </p>
      </div>
      <!--
        <div class="col-lg-6 col-md-6 col-6 mx-auto">
            <small>Package: </small><span class="text-info " v-if="userDetails.user_coupon_status == 1">{{ userDetails.package_name }}</span>
            <span v-else>NILL</span>
            <div class="package">
              
      </div>
      -->
      <div class="col-lg-6 col-md-6 col-6 mx-auto">
        <div class="package">
            <small class="pl-3">Package:</small>
            <h6 class="pl-1" v-if="userDetails.user_coupon_status == 1">{{ userDetails.package_name }}</h6>
            <h6 v-else>NILL</h6>
        </div> 
              
      </div>  
    </div>
    <div class="card-footer">
     
      <hr>
      <div class="button-container">
        <div class="row">
          <div class="col-lg-6 col-md-6 col-6 ml-auto my-auto">
            <small>Status</small><br>
            <h6 v-show="userDetails.user_status == 1">Active</h6>
          </div>
          
          <div class="col-lg-5 col-md-6 col-6 ml-auto my-auto">
            <small>Balance</small><br>
            <h6 v-if="userDetails.user_coupon_status == 1"> SAR {{ userDetails.coupon_balance }}</h6>
            <h6 v-else>Nil</h6>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    props : {
      userDetails:{
        type: Array,
        default: true
      },
      couponDetails:{
        type: Object,
        default: true
      }
    },
    data(){
      return{
        
      }
    }
  }

</script>
<style scoped>
.package{
  display: flex;
}
</style>
