<template>
  <div>
  <div class="row">
    
    <div class="col-lg-4 col-md-5">
    
      <user-card :user-details="user" :coupon-details="coupon">

      </user-card>
    </div>
    <div class="col-lg-8 col-md-7">
      <edit-profile-form>

      </edit-profile-form>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">

    </div>
  </div>
  </div>
</template>
<script>
  import EditProfileForm from './UserProfile/EditProfileForm.vue'
  import UserCard from './UserProfile/UserCard.vue'
  

  export default {
    components: {
      EditProfileForm,
      UserCard,
     
    },
    data(){
      return{
        user:[],
        coupon:[],
      }
    },
    methods:{
      getUser(){
        const user_id = this.$route.query.user_id
        this.$http.get(`dashboard/adm_get_user_details?user_id=${user_id}`)
        .then(response => {
        this.user = response.body.data.user_info
        this.coupon = response.body.data.coupons
      })
      }
    },
    beforeMount(){
      this.getUser()
    }
  }
</script>
<style>

</style>
