<template>
  <div class="card">
    <div class="card-header">
      <h4 class="title">Create Coupon</h4>
    </div>
    <div class="card-body">
      <form>
        <div class="row">
          <div class="col-md-4">
            <label for="country">select country</label>
                <select class="form-control" v-model="selectedCountry" label="Contact Number" placeholder="d" @change="selectCountry">
                <option v-for="country in counrties" :key="country.country_currency_code"  :value="country.country_id">{{ country.country_name }}</option>
                </select>
              </div>
              <div class="col-md-4">
                <label for="country">select package</label>
                <select class="form-control" v-model="selectedPackage" @change="calculateTotal">
                 <option v-for="pkg in packages" :key="pkg.package_id" :value="pkg.package_value">{{ pkg.package_name }}</option>
                </select>
              </div>
              <div class="col-md-4">
                <!--
                  <fg-input type="number"
                      label="number of profiles"
                      v-model="numberOfProfiles"
                      >
                </fg-input>
                -->
                <label for="">number of profiles</label>
                <input type="number" name="" id="" v-model="numberOfProfiles" @keyup="calculateTotal()">
              </div>
        </div>
        

        <div class="text-center btn-div">
          <button type="submit" class="btn btn-success btn-fill btn-wd" @click.prevent="submitCoupon()" >
            Generate Token
          </button>
          <h6 class="my-auto">TOTAL : <span class="">
            {{ currencyCode }} {{ total }}
           </span></h6>
        </div>
        <div class="clearfix"></div>
      </form>
    </div>
    <h6 class="mx-auto" v-if="code !=null ">{{ code }}</h6>
    <p class="text-success mx-auto" v-if="code">Coupon generated successfully</p>
  </div>
</template>
<script>
  export default {
    props : {
      userDetails:{
        type: Object,
        default: true
      }
    },
    data () {
      return {
        total:0,
        packages:[],
        counrties:[],
        selectedCountry:'',
        selectedPackage:0,
        code:'',
        numberOfProfiles: 0,
        couponGenerated:false,
        currencyCode:'',
        
        user: {
          company: 'Paper Dashboard',
          username: 'michael23',
          email: '',
          lastName: 'Faker',
          address: 'Melbourne, Australia',
          city: 'melbourne',
          postalCode: '',
          aboutMe: `Oh so, your weak rhyme. You doubt I'll bother, reading into it.I'll probably won't, left to my own devicesBut that's the difference in our opinions.`
        }
      }
    },
    methods: {
      getPackages(){
        //const user_id = this.$route.query.user_id
        console.log(this.selectedCountry)
        this.$http.get(`dashboard/adm_list_package`,{
          params:{
          country_id:this.selectedCountry
        }
        })
        .then(response => {
          this.packages = response.body.data.data
          console.log(this.packages)
        
      })
      },
      getCountry(){
        //const user_id = this.$route.query.user_id
        this.$http.get(`dashboard/adm_list_country`)
        .then(response => {
        this.counrties = response.body.data.data
        
      })
      },
      submitCoupon(){        
        const user_id = this.$route.query.user_id
        this.$http.post(`dashboard/adm_create_coupon`,{
          coupon_user_id : user_id,
          coupon_value : this.total,
          //coupon_package_id : selected_package.package_id
          //coupon_package_id : this.coupon_package_id
        })
        .then(response => {
          this.couponGenerated = true 
          this.code = response.body.data.data.coupon_code
          console.log('coupon created successfully')
      })
      },
      calculateTotal() {

      if(this.numberOfProfiles == NaN){
        this.numberOfProfiles = 0
      }
       const totalPrice = this.selectedPackage * this.numberOfProfiles
       this.total = totalPrice.toFixed(2)
      
 
    },
    getCurrencyCode(){
      const selectedCountryObject = this.counrties.find(country => country.country_id === this.selectedCountry);
      this.currencyCode = selectedCountryObject.country_currency_code
    },
    selectCountry(){
      
      this.getPackages(),
      this.getCurrencyCode()
      
    },
    },
    watch: {
    numberOfProfiles: function() {
      this.totalPrice = this.calculateTotal();
    }
    },
    beforeMount(){
      this.getPackages(),
      this.getCountry(),
      this.totalPrice = this.calculateTotal();
    },
    
  }

</script>
<style scoped>
  .btn-div{
    display: flex;
    justify-content: space-between;
  }
</style>

